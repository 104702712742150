<template>
  <Page>
    <template v-slot:content>
      <PhotoFilters
        :loading.sync="loading"
        @onFiltersChanged="onFiltersChanged"
      />
      <v-data-iterator
        :footer-props="table.footerProps"
        :items="result.items"
        :loading="result.loading"
        :options.sync="table.options"
        :server-items-length="result.count"
      >
        <template v-slot:loading>
          <v-sheet
            class="text-center pa-6"
            elevation="3"
            rounded
          >
            <div class="text-center mb-4">
              <v-progress-linear
                indeterminate
                rounded
                height="6"
              />
            </div>
            {{ $t('global.loading') }}
          </v-sheet>
        </template>
        <template v-slot:no-data>
          <v-sheet
            class="text-center pa-6"
            elevation="3"
            rounded
          >
            {{ $t('global.no-data') }}
          </v-sheet>
        </template>
        <template v-slot="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.id"
              cols="12"
              class="d-flex child-flex my-4 py-0"
              sm="6"
              md="4"
              lg="3"
              xl="2"
            >
              <v-card>
                <a
                  :href="'/photos/' + item.id"
                  target="_blank"
                >
                  <v-img
                    :src="item.src.list"
                    :lazy-src="item.src.thumbnail"
                    min-height="90%"
                    class="grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        />
                      </v-row>
                    </template>
                  </v-img>
                </a>
                <v-card-actions>
                  <AutomationChip
                    v-for="(automation, index) in item.automations"
                    :key="`automation`+index"
                    :automation="automation"
                    small
                  />
                  <v-spacer />
                  <v-btn
                    :href="item.src.original"
                    icon
                    target="_blank"
                    color="blue lighten-1"
                  >
                    <v-icon>mdi-open-in-new</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </template>
  </Page>
</template>

<script>
import AutomationChip from '@/components/base/Photos/AutomationChip.vue'
import EventBus from '@/plugins/event-bus'
import { getQueryString } from '@/helpers/queryString'
import { mapState } from 'vuex'
import Page from '@/components/core/Page.vue'
import PhotoFilters from '@/views/Photos/Filters.vue'

export default {
  name: 'PhotosList',
  components: { PhotoFilters, AutomationChip, Page },
  data() {
    return {
      request: null,
      loading: false,
      table: {
        options: {
          page: +this.$route.query.page || 1,
          itemsPerPage: 30,
          sortBy: [],
          sortDesc: [],
        },
        footerProps: {
          'items-per-page-options': [30],
          'show-first-last-page': true,
          'show-current-page': true,
        },
      },
      result: {
        items: [],
        count: 0,
        loading: false,
      },
      filters: {
        'drop.id': this.$route.query['drop.id'],
        'store.id': this.$route.query['store.id'],
        position: this.$route.query.position,
      },
    }
  },
  computed: {
    ...mapState(['drops']),
    queryString: function () {
      return 'v3/photos?' + getQueryString(
        this.table.options.page,
        this.filters,
        this.table.options.sortBy,
        this.table.options.sortDesc
      )
    },
  },
  watch: {
    filters() {
      this.table.options.page = 1
    },
    queryString: {
      handler() {
        this.load()
      },
    },
    'table.options.page': function(value) {
      this.$router.replace({ name: 'PhotosList', query:
          { ...this.filters, page : value } }
      )
    },
  },
  mounted() {
    this.load()
  },
  created() {
    EventBus.$on('PHOTOS_REFRESH_LIST', () => {
      this.load()
    })
  },
  methods: {
    onFiltersChanged(filters) {
      this.filters = filters
      this.$router.replace({ name: 'PhotosList', query:
          { ...this.filters, page : this.table.options.page } })
    },
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      
      this.result.loading = true
      this.result.items = []
      this.result.count = 0
      
      this.$axios.get(this.queryString, {
        cancelToken: axiosSource.token,
      })
        .then((response) => {
          this.result.items = response.data['hydra:member']
          this.result.count = response.data['hydra:totalItems']
        })
        .catch((error) => {
          if (!this.$axios.isCancel(error)) {
            // eslint-disable-next-line no-console
            console.log(error)
            this.result.loading = false
          }
        })
        .finally(() => {
          this.request = null
          this.result.loading = false
        })
    },
  },
}
</script>
