<template>
  <Page>
    <template v-slot:content>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-card :loading="loading">
          <v-card-title class="black white--text">
            <span class="headline">{{ $t('views.stores.forms.labels.add_store_seller') }}</span>
          </v-card-title>

          <v-container fluid>
            <v-row class="pa-4">
              <h2>{{ $t('views.stores.forms.labels.about_store') }}</h2>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                sm="6"
                xs="12"
              >
                <v-text-field
                  v-model="store.name"
                  :label="$t('views.stores.headers.name')"
                  :rules="[rules.required]"
                  :hint="$t('views.stores.forms.hint.name')"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="6"
                xs="12"
              >
                <v-select
                  v-model="store.kind"
                  :label="$t('views.stores.headers.kind')"
                  :items="values.kind"
                  :rules="[rules.required]"
                  item-text="label"
                  item-value="value"
                  clearable
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                sm="6"
                xs="12"
              >
                <v-text-field
                  v-model="store.email"
                  :label="$t('views.stores.headers.email')"
                  :rules="[rules.required]"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="6"
                xs="12"
              >
                <v-text-field
                  v-model="store.phone"
                  :label="$t('views.stores.headers.phone')"
                  :rules="[rules.required]"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                sm="6"
                xs="12"
              >
                <v-text-field
                  v-model="store.short_name"
                  :label="$t('views.stores.headers.short_name')"
                  :rules="[rules.required]"
                  :hint="$t('views.stores.forms.hint.short_name')"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row class="pa-4">
              <h2>{{ $t('views.stores.forms.labels.about_seller') }}</h2>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                sm="6"
                xs="12"
              >
                <v-text-field
                  v-model="store.owner.last_name"
                  :label="$t('views.sellers.headers.last_name')"
                  :rules="[rules.required]"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="6"
                xs="12"
              >
                <v-text-field
                  v-model="store.owner.first_name"
                  :label="$t('views.sellers.headers.first_name')"
                  :rules="[rules.required]"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                sm="6"
                xs="12"
              >
                <v-text-field
                  v-model="store.owner.email"
                  :label="$t('views.sellers.headers.email')"
                  :rules="[rules.required]"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="6"
                xs="12"
              >
                <v-text-field
                  v-model="store.owner.phone"
                  :label="$t('views.sellers.headers.phone')"
                  :rules="[rules.required]"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                sm="6"
                xs="12"
              >
                <v-text-field
                  v-model="store.owner.password"
                  :label="$t('views.sellers.headers.password')"
                  :rules="[rules.required, rules.min]"
                  :hint="$t('views.sellers.forms.hint.password')"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="6"
                xs="12"
              >
                <v-text-field
                  v-model="store.owner.repassword"
                  :label="$t('views.sellers.headers.repassword')"
                  :rules="[rules.required, rules.passwordMatch]"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-container>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              @click="save"
            >
              {{ $t('forms.buttons.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </template>
  </Page>
</template>

<script>
import EventBus from '@/plugins/event-bus'
import Page from '@/components/core/Page.vue'

export default {
  name: 'StoresAdd',
  components: { Page },
  data() {
    return {
      valid: false,
      loading:false,
      store: {
        owner: {
          password:'',
          repassword:'',
        },
      },
      values: {
        enabled: [
          { label: this.$i18n.t('views.stores.enum.enabled.yes'), value: 1 },
          { label: this.$i18n.t('views.stores.enum.enabled.no'), value: 0 },

        ],
        kind: [
          { label: this.$i18n.t('views.stores.enum.kind.PASSIONATE'), value: 'PASSIONATE' },
          { label: this.$i18n.t('views.stores.enum.kind.PRO_ONLINE'), value: 'PRO_ONLINE' },
          { label: this.$i18n.t('views.stores.enum.kind.PRO_OFFLINE'), value: 'PRO_OFFLINE' },
          { label: this.$i18n.t('views.stores.enum.kind.B2B'), value: 'B2B' },
          { label: this.$i18n.t('views.stores.enum.kind.UNKNOWN'), value: 'UNKNOWN' },
        ],
      },
      rules: {
        required: value => !!value || this.$i18n.t('forms.fields.required'),
        min: v => v.length >= 8 || 'Min 8 characters',
        passwordMatch: (v) => v === this.store.owner.password || this.$i18n.t('forms.fields.passwords_mismatch'),
      },
    }
  },
  methods: {
    save() {
      this.$refs.form.validate()
      if (!this.valid) return false

      this.loading = true
      this.$axios.post('stores', this.store)
        .then(response => {
          this.loading = false
          this.store = response.data
          this.snackbar(
            'green',
            this.$i18n.t('views.stores.forms.messages.save_succeed', { storeName : this.store.name })
          )
          this.$router.push({ name: 'StoresEdit', params: { id: this.store.id } })
        })
        .catch(error => {
          let error_message =  error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.loading = false
          this.snackbar(
            'error',
            this.$i18n.t('views.stores.forms.messages.save_failed', { error : error_message })
          )
        })
    },
    snackbar(color, message) {
      EventBus.$emit('SNACKBAR', { color : color, message: message })
    },
  },

}
</script>

<style scoped>
</style>
