<template>
  <Page
    :mounted.sync="mounted"
  >
    <template v-slot:title>
      <v-icon class="mr-2">
        mdi-store-outline
      </v-icon>
      Edition d'une boutique:
      <span v-if="store">
        {{ store.name }}
      </span>
    </template>
    
    <template v-slot:content>
      <v-card>
        <v-overlay
          :value="loading"
          absolute
        >
          <v-progress-circular
            indeterminate
            size="64"
          />
          <v-row class="py-6 font-weight-medium">
            <span>{{ loadingText }}</span>
          </v-row>
        </v-overlay>

        <v-card-title class="text-center justify-center py-6">
          <h1 class="font-weight-bold display-3 basil--text">
            {{ store.name }}
          </h1>
        </v-card-title>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-tabs>
            <v-tab>{{ $t('views.stores.forms.tabs.summary') }}</v-tab>
            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                    sm="6"
                    xs="12"
                  >
                    <v-card>
                      <v-card-title color="pink">
                        <v-icon left>
                          mdi-wallet
                        </v-icon>Portefeuille
                      </v-card-title>
                      <v-list two-line>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-timeline-alert-outline
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>A venir</v-list-item-title>
                            <v-list-item-subtitle>En attente de livraison, validation.</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-icon>
                            {{ store.stats_products_pending_price_amount | currency }}
                          </v-list-item-icon>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-timeline-check-outline
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>Disponible</v-list-item-title>
                            <v-list-item-subtitle>Sur sa balance Stripe.</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-icon>
                            {{ store.stripe_balance_amount | currency }}
                          </v-list-item-icon>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-timeline-text-outline
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>Viré</v-list-item-title>
                            <v-list-item-subtitle>Total des virements effectués.</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-icon>
                            {{ store.stripe_total_payout_amount | currency }}
                          </v-list-item-icon>
                        </v-list-item>
                        <v-divider />
                        <v-list-item v-if="store.stripe_account_id">
                          <v-list-item-icon>
                            <v-chip
                              v-if="store.stripe_account_certified"
                              color="green"
                            />
                            <v-chip
                              v-else
                              color="red"
                            />
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>{{ store.stripe_account_id }}</v-list-item-title>
                            <v-list-item-subtitle>
                              <template v-if="store.stripe_account_certified">
                                Compte vérifié
                              </template>
                              <template v-else>
                                Compte non vérifié
                              </template>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-icon>
                            <v-btn
                              :href="'https://dashboard.stripe.com/account/' + store.stripe_account_id"
                              target="_blank"
                            >
                              <v-icon left>
                                mdi-open-in-new
                              </v-icon>Stripe
                            </v-btn>
                          </v-list-item-icon>
                        </v-list-item>
                        <v-list-item v-else>
                          <v-list-item-content>
                            <v-btn
                              color="green darken-1"
                              @click="showTosDialog"
                            >
                              <v-icon left>
                                mdi-cash
                              </v-icon>Créer mon compte Stripe Connect
                            </v-btn>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    sm="6"
                    xs="12"
                  >
                    <v-card>
                      <v-card-title color="pink">
                        <v-icon left>
                          mdi-wardrobe
                        </v-icon>{{ $t('views.stores.forms.tabs.catalog') }}
                      </v-card-title>
                      <v-list two-line>
                        <v-list-item>
                          <v-list-item-icon />

                          <v-list-item-content>
                            <v-list-item-title>{{ $t('views.products.enum.status.PENDING_REVIEW') }}</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ $t('views.stores.forms.labels.product.status_hint.PENDING_REVIEW') }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-icon>
                            -
                          </v-list-item-icon>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon />

                          <v-list-item-content>
                            <v-list-item-title>{{ $t('views.products.enum.status.ACCEPTED') }}</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ $t('views.stores.forms.labels.product.status_hint.ACCEPTED') }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-icon>
                            -
                          </v-list-item-icon>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon />

                          <v-list-item-content>
                            <v-list-item-title>{{ $t('views.products.enum.status.REJECTED') }}</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ $t('views.stores.forms.labels.product.status_hint.REJECTED') }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-icon>
                            -
                          </v-list-item-icon>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon />

                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t('views.products.enum.status.AVAILABLE_FOR_SALE') }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ $t('views.stores.forms.labels.product.status_hint.AVAILABLE_FOR_SALE') }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-icon>
                            -
                          </v-list-item-icon>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon />

                          <v-list-item-content>
                            <v-list-item-title>{{ $t('views.products.enum.status.SOLD') }}</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ $t('views.stores.forms.labels.product.status_hint.SOLD') }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-icon>
                            -
                          </v-list-item-icon>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    sm="6"
                    xs="12"
                  >
                    <v-card>
                      <v-card-title color="pink">
                        <v-icon left>
                          mdi-wallet
                        </v-icon>Activité
                      </v-card-title>
                      <v-list two-line>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-login
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>Dernière connexion</v-list-item-title>
                            <v-list-item-subtitle>-</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-icon>
                            {{ store.last_authentication_date_time | date }}
                          </v-list-item-icon>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-cash-lock-open
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>Négo possible</v-list-item-title>
                            <v-list-item-subtitle>
                              Possibilité de faire une offre sur les pièces de son catalgoue
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-icon>
                            <v-chip
                              v-if="store.opened_to_price_negotiation"
                              color="green"
                            />
                            <v-chip
                              v-else
                              color="red"
                            />
                          </v-list-item-icon>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-face
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>{{ store.kind }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                        <v-list-item>
                          <v-list-item-icon />

                          <v-list-item-content>
                            <v-list-item-title>{{ store.short_name }}</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-icon>
                            <v-btn
                              class="ml-2 mr-2 mb-0"
                              min-width="0"
                              color="#f9dbc2"
                              link
                              target="_blank"
                              :href="getMarketPlaceUrl(store)"
                            >
                              <v-icon left>
                                mdi-flower
                              </v-icon>{{ store.code }}
                            </v-btn>
                          </v-list-item-icon>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab>{{ $t('views.stores.forms.tabs.information') }}</v-tab>
            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <h3 class="font-weight-bold">
                      {{ $t('views.stores.forms.labels.contact') }}
                    </h3>
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        xs="12"
                      >
                        <v-text-field
                          v-model="store.name"
                          :label="$t('views.stores.forms.labels.name')"
                          :rules="[rules.required]"
                          outlined
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        xs="12"
                      >
                        <v-text-field
                          v-model="store.email"
                          :label="$t('views.stores.forms.labels.email')"
                          :rules="[rules.required]"
                          preprend-inner-icon="mdi-email-outline"
                          outlined
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        xs="12"
                      >
                        <v-text-field
                          v-model="store.phone"
                          :label="$t('views.stores.forms.labels.phone')"
                          preprend-inner-icon="mdi-phone-outline"
                          outlined
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        xs="12"
                      >
                        <v-text-field
                          v-model="store.website_url"
                          :label="$t('views.stores.forms.labels.website_url')"
                          preprend-inner-icon="mdi-web"
                          outlined
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <h3 class="font-weight-bold">
                      {{ $t('views.stores.forms.labels.parameters') }}
                    </h3>
                    <v-row>
                      <v-col
                        cols="12"
                        md="4"
                        sm="4"
                        xs="12"
                      >
                        <v-switch
                          v-model="store.enabled"
                          :label="$t('views.stores.forms.labels.enabled')"
                          dense
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        md="4"
                        sm="4"
                        xs="12"
                      >
                        <v-switch
                          v-model="store.opened_to_price_negotiation"
                          :label="$t('views.stores.forms.labels.opened_to_price_negotiation')"
                          dense
                        />
                      </v-col>
                    
                      <v-col
                        cols="12"
                        md="4"
                        sm="4"
                        xs="12"
                      >
                        <v-switch
                          v-model="store.certified"
                          :label="$t('views.stores.forms.labels.certified')"
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                        sm="6"
                        xs="12"
                      >
                        <v-select
                          v-model="store.target"
                          :label="$t('views.stores.forms.labels.target')"
                          :items="target"
                          item-text="label"
                          item-value="value"
                          outlined
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <h3 class="font-weight-bold">
                      {{ $t('views.stores.forms.labels.market_place') }}
                    </h3>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                        sm="6"
                        xs="12"
                      >
                        <v-text-field
                          v-model="store.short_name"
                          :label="$t('views.stores.forms.labels.short_name')"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        sm="6"
                        xs="12"
                      >
                        <v-btn
                          class="ml-2 mr-2 mb-0"
                          min-width="0"
                          color="#f9dbc2"
                          link
                          target="_blank"
                          :href="getMarketPlaceUrl(store)"
                        >
                          <v-icon left>
                            mdi-flower
                          </v-icon>{{ store.code }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <h3 class="font-weight-bold">
                      {{ $t('views.stores.forms.labels.vacancies') }}
                    </h3>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                        sm="6"
                        xs="12"
                      >
                        <v-menu
                          ref="menu_vacancy_start_time"
                          v-model="menu_vacancy_start_time"
                          :close-on-content-click="false"
                          :return-value.sync="store.vacancy_start_datetime"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="store.vacancy_start_datetime"
                              :label="$t('views.stores.forms.labels.vacancy_start_datetime')"
                              prepend-inner-icon="mdi-calendar-month-outline"
                              clearable
                              readonly
                              v-bind="attrs"
                              outlined
                              dense
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="store.vacancy_start_datetime"
                            no-title
                            scrollable
                          >
                            <v-spacer />
                            <v-btn
                              text
                              color="primary"
                              @click="menu_vacancy_start_time = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu_vacancy_start_time.save(store.vacancy_start_datetime)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        sm="6"
                        xs="12"
                      >
                        <v-menu
                          ref="menu_vacancy_end_datetime"
                          v-model="menu_vacancy_end_datetime"
                          :close-on-content-click="false"
                          :return-value.sync="store.vacancy_end_datetime"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="store.vacancy_end_datetime"
                              :label="$t('views.stores.forms.labels.vacancy_end_datetime')"
                              prepend-inner-icon="mdi-calendar-month-outline"
                              clearable
                              readonly
                              outlined
                              dense
                              v-bind="attrs"
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="store.vacancy_end_datetime"
                            no-title
                            scrollable
                          >
                            <v-spacer />
                            <v-btn
                              text
                              color="primary"
                              @click="menu_vacancy_end_datetime = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu_vacancy_end_datetime.save(store.vacancy_end_datetime)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <!-- Billing address -->
            <v-tab>{{ $t('views.stores.forms.tabs.billing') }}</v-tab>
            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <v-card>
                      <v-card-title>
                        Information de facturation
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            xs="12"
                          >
                            <v-text-field
                              v-model="store.billing_name"
                              :label="$t('views.stores.forms.labels.billing_name')"
                              :rules="[rules.required]"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            xs="12"
                          >
                            <v-text-field
                              v-model="store.siret"
                              :label="$t('views.stores.forms.labels.address.siret')"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-title>
                        {{ $t('views.stores.forms.labels.commission') }}
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="12"
                            md="6"
                            sm="6"
                            xs="12"
                          >
                            <v-select
                              v-model="store.kind"
                              :label="$t('views.stores.forms.labels.kind')"
                              :items="kind"
                              item-text="label"
                              item-value="value"
                              :rules="[rules.required]"
                              outlined
                              dense
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                            sm="6"
                            xs="12"
                          >
                            <v-select
                              v-model="store.commission.model"
                              :label="$t('views.stores.forms.labels.commission_model')"
                              :items="commission_model"
                              item-text="label"
                              item-value="value"
                              :rules="[rules.required]"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            md="3"
                            sm="3"
                            xs="6"
                          >
                            <v-select
                              v-model="store.commission.vat_mode"
                              :label="$t('views.stores.forms.labels.commission_vat_mode')"
                              :items="commission_vat_mode"
                              item-text="label"
                              item-value="value"
                              :rules="[rules.required]"
                              outlined
                              dense
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="3"
                            sm="3"
                            xs="6"
                          >
                            <v-text-field
                              v-model="store.commission.vat_rate"
                              append-icon="mdi-percent-outline"
                              readonly
                              disabled
                              :label="$t('views.stores.forms.labels.commission_vat_rate')"
                              :rules="[rules.required]"
                              outlined
                              dense
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="3"
                            sm="3"
                            xs="6"
                          >
                            <v-text-field
                              v-model="store.commission.rate"
                              append-icon="mdi-percent-outline"
                              :label="$t('views.stores.forms.labels.commission_rate')"
                              :rules="[rules.positive]"
                              outlined
                              dense
                            />
                          </v-col>
                        
                          <v-col
                            cols="12"
                            md="3"
                            sm="3"
                            xs="6"
                          >
                            <v-text-field
                              v-model="store.commission.fee.amount"
                              append-icon="mdi-cash"
                              :label="$t('views.stores.forms.labels.commission_fee')"
                              :rules="[rules.positive]"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <v-card>
                      <v-card-title>Adresse facturation</v-card-title>
                      <v-card-subtitle class="py-2">
                        <v-alert
                          type="warning"
                          outlined
                        >
                          Adresse utilisée pour la facturation mensuelle.
                        </v-alert>
                      </v-card-subtitle>
                      <v-card-text>
                        <v-row dense>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            xs="12"
                          >
                            <v-text-field
                              v-model="store.billing_address.company"
                              :label="$t('views.stores.forms.labels.address.company')"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            xs="12"
                          >
                            <v-text-field
                              v-model="store.billing_address.line1"
                              :label="$t('views.stores.forms.labels.address.address_line1')"
                              :rules="[rules.required]"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            xs="12"
                          >
                            <v-text-field
                              v-model="store.billing_address.line2"
                              :label="$t('views.stores.forms.labels.address.address_line2')"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col
                            cols="12"
                            md="4"
                            sm="6"
                            xs="12"
                          >
                            <v-text-field
                              v-model="store.billing_address.postal_code"
                              :label="$t('views.stores.forms.labels.address.postal_code')"
                              :rules="[rules.required]"
                              outlined
                              dense
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                            sm="6"
                            xs="12"
                          >
                            <v-text-field
                              v-model="store.billing_address.city"
                              :label="$t('views.stores.forms.labels.address.city')"
                              :rules="[rules.required]"
                              outlined
                              dense
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                            sm="6"
                            xs="12"
                          >
                            <v-select
                              v-model="store.billing_address.country_code"
                              :label="$t('views.stores.forms.labels.address.country')"
                              :rules="[rules.required]"
                              :items="country"
                              item-text="label"
                              item-value="value"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            xs="12"
                          >
                            <v-text-field
                              v-model="store.billing_address.additional_information"
                              :label="$t('views.stores.forms.labels.address.additional_information')"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                    sm="12"
                    xs="12"
                  >
                    <v-card>
                      <v-card-title>{{ $t('views.stores.forms.tabs.invoices') }}</v-card-title>
                      <v-card-text>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">
                                  {{ $t('views.stores.forms.labels.invoices.year') }}
                                </th>
                                <th class="text-left">
                                  {{ $t('views.stores.forms.labels.invoices.month') }}
                                </th>
                                <th class="text-left">
                                  {{ $t('views.stores.forms.labels.invoices.generated_datetime') }}
                                </th>
                                <th class="text-left">
                                  {{ $t('views.stores.forms.labels.invoices.received_amount_amount') }}
                                </th>
                                <th class="text-left">
                                  {{ $t('views.stores.forms.labels.invoices.commission_applied_amount') }}
                                </th>
                                <th class="text-left">
                                  {{ $t('views.stores.forms.labels.invoices.vat_applied_amount') }}
                                </th>
                                <th class="text-left">
                                  {{ $t('views.stores.forms.labels.invoices.paid_amount') }}
                                </th>
                                <th>-</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="item in invoices"
                                :key="item.id"
                              >
                                <td>{{ item.year }}</td>
                                <td>{{ item.month }}</td>
                                <td>{{ item.generated_datetime | dateSimple }}</td>
                                <td>{{ item.received.amount | currency }}</td>
                                <td>{{ item.commission_applied.amount | currency }}</td>
                                <td>{{ item.vat_applied.amount | currency }}</td>
                                <td>{{ item.paid.amount | currency }}</td>
                                <td align="right">
                                  <v-btn
                                    class="ml-2 mr-2 mb-0"
                                    min-width="0"
                                    color="#f9dbc2"
                                    @click="downloadInvoice(item)"
                                  >
                                    <v-icon>
                                      mdi-download
                                    </v-icon>
                                  </v-btn>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <!-- Shipping -->
            <v-tab>{{ $t('views.stores.forms.tabs.shipping') }}</v-tab>
            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                  >
                    <v-card>
                      <v-card-title>Adresse expéditeur</v-card-title>
                      <v-card-subtitle class="py-2">
                        <v-alert
                          type="info"
                          outlined
                        >
                          Adresse utilisée en cas de "Retour expéditeur" si le colis n'a pu être livré au destinataire.
                        </v-alert>
                      </v-card-subtitle>
                      <v-card-text>
                        <v-row dense>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            xs="12"
                          >
                            <v-text-field
                              v-model="warehouse.address.recipient_name"
                              :label="$t('views.stores.forms.labels.address.recipient_name')"
                              :rules="[rules.required]"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            xs="12"
                          >
                            <v-text-field
                              v-model="warehouse.address.company"
                              :label="$t('views.stores.forms.labels.address.company')"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            xs="12"
                          >
                            <v-text-field
                              v-model="warehouse.address.line1"
                              :label="$t('views.stores.forms.labels.address.address_line1')"
                              :rules="[rules.required]"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            xs="12"
                          >
                            <v-text-field
                              v-model="warehouse.address.line2"
                              :label="$t('views.stores.forms.labels.address.address_line2')"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col
                            cols="12"
                            md="4"
                            sm="6"
                            xs="12"
                          >
                            <v-text-field
                              v-model="warehouse.address.postal_code"
                              :label="$t('views.stores.forms.labels.address.postal_code')"
                              :rules="[rules.required]"
                              outlined
                              dense
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                            sm="6"
                            xs="12"
                          >
                            <v-text-field
                              v-model="warehouse.address.city"
                              :label="$t('views.stores.forms.labels.address.city')"
                              :rules="[rules.required]"
                              outlined
                              dense
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                            sm="6"
                            xs="12"
                          >
                            <v-select
                              v-model="warehouse.address.country_code"
                              :label="$t('views.stores.forms.labels.address.country')"
                              :rules="[rules.required]"
                              :items="country"
                              item-text="label"
                              item-value="value"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            xs="12"
                          >
                            <v-text-field
                              v-model="warehouse.address.additional_information"
                              :label="$t('views.stores.forms.labels.address.additional_information')"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                  >
                    <v-card>
                      <v-card-title>Adresse de retour</v-card-title>
                      <v-card-subtitle class="py-2">
                        <v-alert
                          type="warning"
                          outlined
                        >
                          Adresse utilisée en cas de demande de retour de la part du client.
                        </v-alert>
                      </v-card-subtitle>
                      <v-card-text>
                        <v-row dense>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            xs="12"
                          >
                            <v-text-field
                              v-model="warehouse.return_address.recipient_name"
                              :label="$t('views.stores.forms.labels.address.recipient_name')"
                              :rules="[rules.required]"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            xs="12"
                          >
                            <v-text-field
                              v-model="warehouse.return_address.company"
                              :label="$t('views.stores.forms.labels.address.company')"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            xs="12"
                          >
                            <v-text-field
                              v-model="warehouse.return_address.line1"
                              :label="$t('views.stores.forms.labels.address.address_line1')"
                              :rules="[rules.required]"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            xs="12"
                          >
                            <v-text-field
                              v-model="warehouse.return_address.line2"
                              :label="$t('views.stores.forms.labels.address.address_line2')"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col
                            cols="12"
                            md="4"
                            sm="6"
                            xs="12"
                          >
                            <v-text-field
                              v-model="warehouse.return_address.postal_code"
                              :label="$t('views.stores.forms.labels.address.postal_code')"
                              :rules="[rules.required]"
                              outlined
                              dense
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                            sm="6"
                            xs="12"
                          >
                            <v-text-field
                              v-model="warehouse.return_address.city"
                              :label="$t('views.stores.forms.labels.address.city')"
                              :rules="[rules.required]"
                              outlined
                              dense
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                            sm="6"
                            xs="12"
                          >
                            <v-select
                              v-model="warehouse.return_address.country_code"
                              :label="$t('views.stores.forms.labels.address.country')"
                              :rules="[rules.required]"
                              :items="country"
                              item-text="label"
                              item-value="value"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            xs="12"
                          >
                            <v-text-field
                              v-model="warehouse.return_address.additional_information"
                              :label="$t('views.stores.forms.labels.address.additional_information')"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <v-card class="font-weight-bold">
                      <v-card-title>
                        {{ $t('views.stores.forms.labels.note') }}
                      </v-card-title>
                      <v-card-text>
                        <em v-if="note.updated_at">
                          {{
                            $t('views.stores.forms.labels.last_note_update', {
                              date: (new Date(note.updated_at)).toLocaleString()
                            })
                          }}
                        </em>
                        <v-textarea
                          v-model="note.text"
                          background-color="amber lighten-4"
                        />
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          @click="saveNote"
                        >
                          {{ $t('forms.buttons.save') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <v-card>
                      <v-card-title>
                        {{ $t('views.stores.forms.labels.logistic_configuration') }}
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="12"
                            md="3"
                            sm="4"
                            xs="12"
                          >
                            <v-text-field
                              v-model="warehouse.name"
                              :label="$t('views.stores.forms.labels.warehouse_name')"
                              outlined
                              dense
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="3"
                            sm="4"
                            xs="12"
                          >
                            <v-select
                              v-model="warehouse.provider"
                              item-text="label"
                              item-value="value"
                              :items="providers"
                              :label="$t('views.stores.forms.labels.provider')"
                              outlined
                              dense
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                            sm="4"
                            xs="12"
                          >
                            <CarrierSelector
                              :state.sync="warehouse.available_carriers"
                              dense
                              multiple
                              default-only
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            md="3"
                            sm="4"
                            xs="12"
                          >
                            <v-text-field
                              v-model="warehouse.email"
                              :label="$t('views.stores.forms.labels.email')"
                              outlined
                              dense
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="3"
                            sm="4"
                            xs="12"
                          >
                            <v-text-field
                              v-model="warehouse.phone"
                              :label="$t('views.stores.forms.labels.phone')"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-title>
                        {{ $t('views.stores.forms.labels.shipping_conditions') }}
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            xs="12"
                          >
                            <h4 class="font-weight-bold">
                              {{ $t('views.stores.forms.labels.shipping_conditions_fr') }}
                            </h4>
                            <v-textarea
                              v-model="warehouse.shipping_conditions.short.fr"
                              :label="$t('views.stores.forms.labels.shipping_conditions_short')"
                              rows="2"
                              outlined
                              dense
                            />
                            <v-textarea
                              v-model="warehouse.shipping_conditions.long.fr"
                              :label="$t('views.stores.forms.labels.shipping_conditions_long')"
                              rows="3"
                              outlined
                              dense
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            xs="12"
                          >
                            <h4 class="font-weight-bold">
                              {{ $t('views.stores.forms.labels.shipping_conditions_en') }}
                            </h4>
                            <v-textarea
                              v-model="warehouse.shipping_conditions.short.en"
                              :label="$t('views.stores.forms.labels.shipping_conditions_short')"
                              rows="2"
                              outlined
                              dense
                            />
                            <v-textarea
                              v-model="warehouse.shipping_conditions.long.en"
                              :label="$t('views.stores.forms.labels.shipping_conditions_long')"
                              rows="3"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <!-- Sellers -->
            <v-tab>{{ $t('views.stores.forms.tabs.catalog') }}</v-tab>
            <v-tab-item>
              <v-container fluid>
                <v-card>
                  <v-card-title>
                    Guide des tailles
                  </v-card-title>
                  <v-card-text>
                    <v-form @submit.prevent="uploadSizeGuide">
                      <v-row v-if="store.size_guide">
                        <v-col
                          cols="12"
                          md="12"
                          sm="12"
                        >
                          <a :href="store.size_guide">{{ store.size_guide }}</a>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="6"
                          md="6"
                          sm="6"
                        >
                          <v-file-input
                            v-model="sizeGuideFile"
                            :prepend-icon="null"
                            prepend-inner-icon="mdi-file-delimited-outline"
                            accept="application/pdf"
                            placeholder="Fichier pdf"
                            label="Fichier pdf"
                            outlined
                            hide-details
                            dense
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="6"
                          md="6"
                          sm="6"
                          align="right"
                        >
                          <v-btn
                            type="submit"
                            color="primary"
                          >
                            {{ $t('forms.buttons.upload') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-tab-item>

            <!-- Sellers -->
            <v-tab>{{ $t('views.stores.forms.tabs.sellers') }}</v-tab>
            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                    sm="12"
                    xs="12"
                  >
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t('views.sellers.forms.labels.edit') }}
                            </th>
                            <th class="text-left">
                              {{ $t('views.sellers.forms.labels.full_name') }}
                            </th>
                            <th class="text-left">
                              {{ $t('views.sellers.forms.labels.email') }}
                            </th>
                            <th class="text-left">
                              {{ $t('views.sellers.forms.labels.last_authentication_date_time') }}
                            </th>
                            <th class="text-left">
                              {{ $t('views.sellers.forms.labels.enabled') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in store.sellers"
                            :key="item.id"
                          >
                            <td>
                              <v-btn
                                icon
                                :to="`/sellers/${item.id}`"
                              >
                                <v-icon left>
                                  mdi-square-edit-outline
                                </v-icon>
                              </v-btn>
                            </td>
                            <td>{{ item.first_name }} {{ item.last_name }}</td>
                            <td>{{ item.email }}</td>
                            <td>{{ item.last_authentication_date_time | dateSimple }}</td>
                            <td>
                              <v-chip
                                v-if="item.enabled"
                                color="green"
                              />
                              <v-chip
                                v-else
                                color="red"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-form>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="save"
          >
            {{ $t('forms.buttons.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-dialog
        v-model="stripeTos.visible"
        width="500"
      >
        <v-card
          :loading="stripeTos.loading"
        >
          <v-card-title class="headline grey lighten-2">
            {{ $t('views.stores.forms.labels.connect_account_create') }}
          </v-card-title>

          <v-card-text>
            <p class="pt-5 pb-5">
              En cliquant sur le bouton suivant, vous acceptez <a href="#">nos Conditions Générales d\'Utilisation</a>
              ainsi que les <a
                href="https://stripe.com/fr/connect-account/legal#translation"
                target="_blank"
              >Conditions d\'Utilisation des Comptes Connectés Stripe</a>
            </p>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="createConnectAccount()"
            >
              J'accepte
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </Page>
</template>

<script>
import { AxiosError } from 'axios'
import CarrierSelector from '@/components/base/Shipment/Input/CarrierSelector.vue'
import EventBus from '@/plugins/event-bus'
import { loadStripe } from '@stripe/stripe-js'
import Page from '@/components/core/Page.vue'

export default {
  name: 'StoresEdit',
  components: { Page, CarrierSelector },
  data() {
    return {
      valid: false,
      loading:false,
      mounted:false,
      loadingText: this.$i18n.t('views.products.forms.messages.load.progress'),
      invoices: {},
      note: {
        text: null,
      },
      store: {
        name: '-',
        commission: {
          model: null,
          vat_mode: null,
          rate: 0,
          vat_rate: null,
          fee: {
            amount: 0,
            currency: 'EUR',
          },
        },
        billing_address: {},
      },
      warehouse: {
        address: {},
        return_address: {},
        shipping_conditions: {
          short: { fr: '', en: '' },
          long:  { fr: '', en: '' },
        },
      },
      stripeTos: {
        loading: false,
        visible : false,
      },
      menu_vacancy_start_time: null,
      menu_vacancy_end_datetime: null,
      rules: {
        required: value => !!value || this.$i18n.t('forms.fields.required'),
        positive: value => value >= 0 || this.$i18n.t('forms.fields.required'),
      },
      country: [
        { label: 'Allemagne', value : 'DE' },
        { label: 'Belgique', value : 'BE' },
        { label: 'Danemark', value : 'DK' },
        { label: 'Espagne', value : 'ES' },
        { label: 'France', value : 'FR' },
        { label: 'Grande-Bretagne', value : 'GB' },
        { label: 'Etats-Unis', value : 'US' },
        { label: 'Italie', value : 'IT' },
        { label: 'Pays-Bas', value : 'NL' },
        { label: 'Bulgarie', value : 'BG' },
        { label: 'Suisse', value : 'CH' },
        { label: 'Uruguay', value : 'UY' },
      ],
      kind: [
        { label: this.$i18n.t('views.stores.enum.kind.PASSIONATE'), value: 'PASSIONATE' },
        { label: this.$i18n.t('views.stores.enum.kind.PRO_ONLINE'), value: 'PRO_ONLINE' },
        { label: this.$i18n.t('views.stores.enum.kind.PRO_OFFLINE'), value: 'PRO_OFFLINE' },
        { label: this.$i18n.t('views.stores.enum.kind.B2B'), value: 'B2B' },
        { label: this.$i18n.t('views.stores.enum.kind.UNKNOWN'), value: 'UNKNOWN' },
      ],
      commission_vat_mode: [
        { label: 'TTC', value: 'TTC' },
        { label: 'HT', value: 'HT' },
      ],
      commission_model: [
        { label: 'Commission', value: 'commission' },
        { label: 'Achat/Revente', value: 'purchase_resale' },
      ],
      target: [
        { label: 'Femmes', value: 'women' },
        { label: 'Hommes', value: 'men' },
        { label: 'Enfants', value: 'kids' },
      ],
      providers: [
        { label: 'SendCloud',           value: 'SENDCLOUD' },
        { label: 'Wing (PickPackShip)', value: 'WING_B2B' },
        { label: 'Externe', value: 'EXTERNAL' },
      ],
      sizeGuideFile: null,
    }
  },
  beforeMount() {
    this.load()
  },
  methods: {
    getMarketPlaceUrl(store) {
      return process.env.VUE_APP_MP_BASE_URL + store.marketplace_url
    },
    downloadInvoice(invoice) {
      this.$axios.get('/invoices/' + invoice.id + '/file',
        { responseType: 'arraybuffer' }
      ).then(response => {
        let blob = new Blob([response.data], { type: 'application/pdf' }),
          url = window.URL.createObjectURL(blob)

        window.open(url)
      })
    },
    async load() {
      if (!this.$route.params.id) {
        return
      }
      this.loading = true
      const responseStore = await this.$axios.get('stores/' + this.$route.params.id)
      this.store = responseStore.data

      const warehouseResponse = await this.$axios.get('v3/warehouses/' + this.store.warehouse.id)
      this.warehouse = warehouseResponse.data
      this.warehouse.shipping_conditions = {
        short: { fr: null, en: null, ... this.warehouse.shipping_conditions.short },
        long: { fr: null, en: null, ... this.warehouse.shipping_conditions.long },
      }
      
      try {
        const responseStoreNote = await this.$axios.get('stores/' + this.$route.params.id + '/note')
        this.note = responseStoreNote.data
      } catch (e) {
        if (!(e instanceof AxiosError) || e?.response?.status !== 404) {
          throw e
        }
      }

      const responseInvoices = await this.$axios.get('invoices/?store_id=' + this.$route.params.id)
      this.invoices = responseInvoices.data['hydra:member']

      this.loading = false
      this.mounted = true
    },
    saveNote() {
      this.loading = true
      this.$axios.post('v2/stores/' + this.$route.params.id + '/write-note', { text: this.note.text })
        .then((response) => {
          this.loading = false
          this.note = response.data
          this.snackbar(
            'green',
            this.$i18n.t('views.stores.forms.messages.save_note_succeed')
          )
        })
        .catch(error => {
          let error_message =  error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.loading = false
          this.snackbar(
            'error',
            this.$i18n.t('views.stores.forms.messages.save_note_failed', { error : error_message })
          )
        })
    },
    async save() {
      this.$refs.form.validate()
      if (!this.valid) return false
      
      this.loading = true
      if (await this.saveStore()) {
        await this.saveWarehouse()
      }

      this.loading = false
      this.loadingText = this.$i18n.t('views.products.forms.messages.load.progress')
    },
    async saveStore() {
      const store = {
        ...this.store,
        commission: {
          model: this.store.commission.model,
          rate: this.store.commission.rate,
          fee: {
            amount: this.store.commission.fee.amount,
            currency: this.store.commission.fee.currency,
          },
          vat_rate: this.store.commission.vat_rate,
          vat_mode: this.store.commission.vat_mode,
        },
      }
      
      store.billing_address.country = this.store.billing_address.country_code === 'BEL' ? 'Belgique' : 'France'

      try {
        this.loadingText = 'Sauvegarde des infos de la boutique'
        const response = await this.$axios.put('v2/stores/' + this.$route.params.id, store)
        this.store = response.data
        this.snackbar(
          'green',
          this.$i18n.t('views.stores.forms.messages.save_succeed', { storeName : this.store.name })
        )
        
        return true
      } catch (error) {
        let error_message =  error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
        this.snackbar(
          'error',
          this.$i18n.t('views.stores.forms.messages.save_failed', { error : error_message })
        )
        
        return false
      }
    },
    async saveWarehouse() {
      try {
        this.loadingText = 'Sauvegarde configuration Logistique'
        const response = await this.$axios.put('v3/warehouses/' + this.warehouse.id, this.warehouse)
        this.warehouse = response.data
        this.warehouse.shipping_conditions = {
          short: { fr: null, en: null, ... this.warehouse.shipping_conditions.short },
          long: { fr: null, en: null, ... this.warehouse.shipping_conditions.long },
        }
        this.snackbar(
          'green',
          this.$i18n.t('views.stores.forms.messages.save_succeed', { storeName : this.store.name })
        )
        
        return true
      } catch (error) {
        let error_message =  error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
        this.snackbar(
          'error',
          this.$i18n.t('views.stores.forms.messages.save_failed', { error : error_message })
        )
        
        return false
      }
    },
    snackbar(color, message) {
      EventBus.$emit('SNACKBAR', { color : color, message: message })
    },
    showTosDialog() {
      this.stripeTos.visible = true
    },
    async createConnectAccount() {
      const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY)
      const token = await stripe.createToken('account', {
        business_type: 'individual',
        individual: {
          email: this.store.email,
        },
        tos_shown_and_accepted: true,
      })
      this.stripeTos.loading = true
      this.$axios.post('stores/' + this.$route.params.id + '/accounts', { token: token.token.id })
        .then((response) => {
          this.store = response.data
          this.stripeTos.loading = false
          this.stripeTos.visible = false
          this.snackbar(
            'green',
            this.$i18n.t('views.stores.forms.messages.save_succeed', { storeName : this.store.name })
          )
        })
        .catch(error => {
          this.stripeTos.loading = false
          this.stripeTos.visible = false
          let error_message =  error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.snackbar(
            'error',
            this.$i18n.t('views.stores.forms.messages.save_failed', { error : error_message })
          )
        })
    },
    async uploadSizeGuide() {
      if (this.sizeGuideFile === null) {
        return
      }
      this.loading = true
      this.loadingText = 'Sauvegarde du guide des tailles'

      const formData = new FormData()
      formData.append('file', this.sizeGuideFile)

      try {
        const response = await this.$axios.post(
          'v2/stores/' + this.$route.params.id + '/upload-size-guide',
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )

        this.store = response.data
        this.snackbar(
          'green',
          this.$i18n.t('views.stores.forms.messages.save_succeed', { storeName : this.store.name })
        )
        this.sizeGuideFile = null
      } catch (error) {
        let error_message =  error?.response?.data['hydra:description'] ??
          error?.response?.data['detail'] ??
          'No context'
        this.snackbar(
          'error',
          this.$i18n.t('views.stores.forms.messages.save_failed', { error : error_message })
        )
      } finally {
        this.loading = false
        this.loadingText = null
        this.sizeGuideFile = null

      }
    },
  },
}
</script>

<style scoped>
</style>
