<template>
  <v-sheet
    elevation="3"
    rounded
    class="mb-4"
  >
    <v-container
      fluid
    >
      <v-row
        dense
      >
        <v-col
          cols="12"
          lg="3"
          md="3"
        >
          <ProductSelector
            v-model="filters['full_reference']"
          />
        </v-col>
        <v-col
          cols="12"
          lg="3"
          md="3"
        >
          <CategorySelector v-model="filters['category_id[]']" />
        </v-col>
        <v-col
          cols="12"
          lg="3"
          md="3"
        >
          <FullTextInput :state.sync="filters['query']" />
        </v-col>
        <v-col
          cols="12"
          lg="3"
          md="3"
        >
          <ProductStateSelector
            :state.sync="filters['state_and_reason[]']"
            :label="$t('views.products.filters.state')"
          />
        </v-col>
        <v-col
          cols="12"
          lg="3"
          md="3"
        >
          <InWarehouseSelector
            :state.sync="filters['in_imparfaite_warehouse']"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          lg="3"
          md="3"
        >
          <StoreSelector
            v-model="filters['store.id']"
            filter
          />
        </v-col>
        <v-col
          cols="12"
          lg="2"
          md="2"
        >
          <DropsSelector
            v-model="filters['drop.id']"
            filter
          />
        </v-col>
        <v-col
          cols="12"
          lg="2"
          md="2"
        >
          <TagSelector
            v-model="filters['tag.id']"
            filter
          />
        </v-col>
        <v-col
          cols="12"
          md="2"
          lg="2"
        >
          <v-switch
            v-model="filters.stock_only"
            hide-details
            dense
            class="ml-2 mt-1"
            label="Avec stock"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import CategorySelector from '@/components/base/Product/Input/CategorySelector.vue'
import { debounce } from '@/helpers/debounce'
import DropsSelector from '@/components/base/Product/Input/DropsSelector'
import FullTextInput from '@/components/base/Product/Input/FullTextInput.vue'
import { getQueryAsArray } from '@/helpers/queryString'
import InWarehouseSelector from '@/components/base/Product/Input/InWarehouseSelector.vue'
import ProductSelector from '@/components/base/Product/Input/ProductSelector.vue'
import ProductStateSelector from '@/components/core/ProductStateSelector.vue'
import StoreSelector from '@/components/base/StoreSelector.vue'
import TagSelector from '@/components/base/Product/Input/TagSelector.vue'

export default {
  name: 'ProductFilters',
  components: {
    FullTextInput,
    TagSelector,
    ProductSelector,
    CategorySelector,
    InWarehouseSelector,
    ProductStateSelector,
    StoreSelector,
    DropsSelector,
  },
  data() {
    return {
      filters: {
        full_reference: this.$route.query.full_reference,
        query: this.$route.query.query,
        'state_and_reason[]': getQueryAsArray(this.$route.query, 'state_and_reason[]'),
        'store.id': this.$route.query['store.id'],
        'drop.id': this.$route.query['drop.id'],
        'tag.id': this.$route.query['tag.id'],
        in_imparfaite_warehouse: this.$route.query['in_imparfaite_warehouse'],
        'category_id[]': getQueryAsArray(this.$route.query, 'category_id[]'),
        stock_only: this.$route.query['stock_only'] === 'true',
      },
    }
  },
  watch: {
    filters: {
      handler: debounce(function (value) {
        this.$emit('onFiltersChanged', value)
      }, 500),
      deep: true,
    },
  },
}
</script>

<style scoped>
</style>
