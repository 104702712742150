<template>
  <v-chip
    class="mx-2 my-2"
    :small="small"
    label
    :class="getColor(automation)"
  >
    {{ $t('enum.automation.type.' + automation.type.toLowerCase()) }} :
    {{ $t('enum.automation.state.' + automation.state.toLowerCase()) }}
  </v-chip>
</template>

<script>
export default {
  name: 'AutomationChip',
  
  props: {
    automation: {
      type: Object,
      default: () => ({
        type: '',
        state: '',
      }),
      required: true,
    },
    small: {
      type: Boolean,
    },
  },
  methods: {
    getColor(automation) {
      let type = ''
      let state = ''

      switch (automation.type.toLowerCase()) {
      case 'clipping':
        type = 'green'
        break
      case 'headless_crop':
        type = 'orange'
        break
      }
      
      switch (automation.state.toLowerCase()) {
      case 'requested':
        state = ' lighten-3'
        break
      case 'complete':
        state = ' lighten-1'
        break
      }
      
      return type+''+state
    },
  },
}
</script>
