<template>
  <Page>
    <template v-slot:content>
      <PriceNegotiationFilters
        @onFiltersChanged="onFiltersChanged"
      />
      <v-sheet
        elevation="3"
        rounded
      >
        <v-data-table
          :footer-props="table.footerProps"
          :headers="table.headers"
          :items="result.items"
          :loading="result.loading"
          :options.sync="table.options"
          :page="table.options.page"
          :server-items-length="result.count"
          @update:page="load"
          @update:sort-by="load"
          @update:sort-desc="load"
        >
          <template v-slot:loading>
            <v-sheet
              class="text-center pa-6"
            >
              {{ $t('global.loading') }}
            </v-sheet>
          </template>
        
          <template v-slot:no-data>
            <v-sheet
              class="text-center pa-6"
            >
              {{ $t('global.no-data') }}
            </v-sheet>
          </template>
        
          <template v-slot:item.title="{ item }">
            <v-card
              flat
              outlined
              color="transparent"
            >
              <div class="d-flex">
                <v-avatar
                  class="ma-3"
                  size="90"
                  tile
                >
                  <v-img :src="item.product.photos[0].photo.src.thumbnail" />
                </v-avatar>
                <div class="flex-grow-1 flex-shrink-0 flex-wrap">
                  <v-card-title
                    class="text-h6"
                  >
                    {{ item.product.title }}
                    <v-icon
                      v-if="item.auto === true"
                      color="purple darken-3"
                      class="ml-3"
                    >
                      mdi-auto-mode
                    </v-icon>
                  </v-card-title>
                  <v-card-subtitle v-text="item.product.reference" />
                  <v-card-subtitle
                    class="pt-2"
                    v-text="item.product.store.name"
                  />
                </div>
              </div>
            </v-card>
          </template>
          <template v-slot:item.who="{ item }">
            <v-card
              outlined
              color="transparent"
              flat
              class="fill-height"
            >
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title
                    class="text-h6"
                    v-text="item.first_name + ' ' + item.last_name "
                  />

                  <v-card-subtitle v-text="item.email" />
                </div>
              </div>
            </v-card>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip
              class="ma-2"
              :color="$t(`status.price_negotiations.${item.status}.color`)"
              text-color="white"
            >
              {{ $t(`status.price_negotiations.${item.status}.label`) }}
            </v-chip>
          </template>
          <template v-slot:item.regular_price="{ item }">
            {{ item.regular_price|currency }}
          </template>
          <template v-slot:item.proposed_price="{ item }">
            {{ item.proposed_price|currency }}
          </template>
          <template v-slot:item.agreed_price="{ item }">
            <template
              v-if="item.status === 'PENDING'"
            >
              <v-text-field
                v-model="item.agreed_price"
                type="number"
                suffix="€"
                :rules="[
                  (value) => { return parseFloat(value) >= parseFloat(item.proposed_price) || 'Prix trop bas' },
                  (value) => { return parseFloat(value) <= parseFloat(item.regular_price) || 'Prix trop haut' }
                ]"
              />
            </template>
            <template v-else>
              {{ item.agreed_price|currency }}
            </template>
          </template>
          <template v-slot:item.expiration_datetime="{ item }">
            {{ item.expiration_datetime | date }}
          </template>
          <template v-slot:item.valid_until_datetime="{ item }">
            {{ item.valid_until_datetime | date }}
          </template>
          <template v-slot:item.actions="{ item }">
            <template
              v-if="item.status === 'PENDING'"
            >
              <v-btn
                color="green darken-3"
                class="mb-2 pointer"
                elevation="2"
                icon
                outlined
                @click="accept(item)"
              >
                <v-icon>
                  mdi-check
                </v-icon>
              </v-btn>
              <br>
              <v-btn
                color="red darken-3"
                class="mt-2 pointer"
                elevation="2"
                icon
                outlined
                @click="reject(item)"
              >
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </template>
          </template>
        </v-data-table>
      </v-sheet>
    </template>
  </Page>
</template>

<script>
import { debounce } from '@/helpers/debounce'
import { getQueryString } from '@/helpers/queryString'
import Page from '@/components/core/Page.vue'
import PriceNegotiationFilters from '@/views/PriceNegotiations/Filters.vue'
import snackbarMixin from '@/mixins/snackbar'

export default {
  name: 'PriceNegotiationsList',
  components: { Page, PriceNegotiationFilters },
  mixins: [snackbarMixin],
  data() {
    return {
      request: null,
      table: {
        options: {
          page: +this.$route.query.page || 1,
          itemsPerPage: 30,
          sortBy: ['expiration_datetime'],
          sortDesc: ['desc'],
        },
        headers: [
          {
            text: this.$i18n.t('views.price_negotiations.headers.title'),
            value: 'title',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.price_negotiations.headers.who'),
            value: 'who',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.price_negotiations.headers.status'),
            value: 'status',
            sortable: true,
          },
          {
            text: this.$i18n.t('views.price_negotiations.headers.regular_price'),
            value: 'regular_price',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.price_negotiations.headers.proposed_price'),
            value: 'proposed_price',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.price_negotiations.headers.agreed_price'),
            value: 'agreed_price',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.price_negotiations.headers.expiration_datetime'),
            value: 'expiration_datetime',
            sortable: true,
          },
          {
            text: this.$i18n.t('views.price_negotiations.headers.valid_until_datetime'),
            value: 'valid_until_datetime',
            sortable: true,
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
          },
        ],
        footerProps: {
          'items-per-page-options': [30],
          'show-first-last-page': true,
          'show-current-page': true,
        },
      },
      result: {
        items: [],
        count: 0,
        loading: false,
      },
      filters: {
        product : this.$route.query['product'],
        status: this.$route.query['status'],
        store_id : this.$route.query['store_id'],
        email : this.$route.query['email'],
        auto : this.$route.query['auto'],
      },
    }
  },
  computed: {
    queryString: function () {
      return '/v3/price_negotiations?' + getQueryString(
        this.table.options.page,
        this.filters,
        this.table.options.sortBy,
        this.table.options.sortDesc
      )
    },
  },
  watch: {
    filters() {
      this.table.options.page = 0
    },
    queryString: {
      handler: debounce(function () {
        this.load()
      }, 500),
      deep: true,
    },
    'table.options.page': function(value) {
      this.$router.replace({ name: 'PriceNegotiationsList', query:
          { ...this.filters, page : value } }
      )
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    onFiltersChanged(filters) {
      this.filters = filters
      this.$router.replace({ name: 'PriceNegotiationsList', query:
          { ...this.filters, page : this.table.options.page } }
      )
    },
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      this.result.loading = true
      this.result.items = []
      this.result.count = 0
      
      this.$axios.get(this.queryString, {
        cancelToken: axiosSource.token,
      })
        .then((response) => {
          this.result.items = response.data['hydra:member']
          this.result.count = response.data['hydra:totalItems']
          this.result.loading = false
        })
        .catch((error) => {
          if (!this.$axios.isCancel(error)) {
            // eslint-disable-next-line no-console
            console.log(error)
            this.result.loading = false
          }
        })
        .finally(() => {
          this.request = null
        })
    },
    accept(item) {
      this.cancel()
      
      if (parseFloat(item.agreed_price) > parseFloat(item.regular_price)
        || parseFloat(item.agreed_price) < parseFloat(item.proposed_price)) {
        this.snackbarError('Invalid final price')
        
        return
      }
      
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }

      this.table.loading = true
      this.$axios.post('/v3/price_negotiations/'+ item.id +'/accepts', {
        agreed_price: parseFloat(item.agreed_price),
      }, {
        cancelToken: axiosSource.token,
      })
        .then((response) => {
          const editedIndex = this.items.indexOf(item)
          Object.assign(this.items[editedIndex], response.data)
          this.table.loading = false
        })
        .catch((error) => {
          if (!this.$axios.isCancel(error)) {
            // eslint-disable-next-line no-console
            console.log(error)
            this.table.loading = false
          }
        })
        .finally(() => {
          this.request = null
        })
    },
    reject(item) {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }

      this.table.loading = true
      this.$axios.post('/v3/price_negotiations/'+ item.id +'/rejects', {
        cancelToken: axiosSource.token,
      })
        .then((response) => {
          const editedIndex = this.items.indexOf(item)
          Object.assign(this.items[editedIndex], response.data)
          this.table.loading = false
        })
        .catch((error) => {
          if (!this.$axios.isCancel(error)) {
            // eslint-disable-next-line no-console
            console.log(error)
            this.table.loading = false
          }
        })
        .finally(() => {
          this.request = null
        })
    },
  },
}
</script>

<style scoped>
</style>
