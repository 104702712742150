<template>
  <Page>
    <template v-slot:toolbar-buttons>
      <ButtonAction
        :action="openCreationModal"
        icon="mdi-connection"
        text="Créer"
      />
    </template>

    <template v-slot:content>
      <v-sheet
        elevation="3"
        rounded
      >
        <v-data-table
          :footer-props="table.footerProps"
          :headers="table.headers"
          :items="result.items"
          :loading="result.loading"
          :options.sync="table.options"
          :page="table.options.page"
          :server-items-length="result.count"
          @update:page="load"
          @update:sort-by="load"
          @update:sort-desc="load"
        >
          <template v-slot:loading>
            <v-sheet
              class="text-center pa-6"
            >
              {{ $t('global.loading') }}
            </v-sheet>
          </template>
          <template v-slot:no-data>
            <v-sheet
              class="text-center pa-6"
            >
              {{ $t('global.no-data') }}
            </v-sheet>
          </template>
          <template v-slot:item.name="{ item }">
            <ExternalCatalogProviderLink :provider="item" />
          </template>
          <template v-slot:item.source="{ item }">
            {{ $t('views.external_catalog.classifications.enum.sources.' + item.source) }}
          </template>
          <template v-slot:item.store="{ item }">
            <StoreLink :store="item.store" />
          </template>
        </v-data-table>
      </v-sheet>
      <ProviderCreationModal
        :opened.sync="modal.opened"
        @submited="load"
      />
    </template>
  </Page>
</template>

<script>
import ButtonAction from '@/components/base/Toolbar/ButtonAction.vue'
import ExternalCatalogProviderLink from '@/components/base/Link/ExternalCatalogProvider.vue'
import { getQueryString } from '@/helpers/queryString'
import Page from '@/components/core/Page.vue'
import ProviderCreationModal from '@/views/ExternalCatalog/Providers/Modal/CreationModal.vue'
import StoreLink from '@/components/base/Link/Store.vue'

export default {
  name: 'ExternalCatalogProvidersList',
  components: { ProviderCreationModal, ButtonAction, Page, ExternalCatalogProviderLink, StoreLink },
  data() {
    const itemsPerPage = 30
    const currentPage = +this.$route.query.page || 1
    
    return {
      loading: false,
      request: null,
      modal: {
        opened: false,
        selectedItem: null,
      },
      table: {
        options: {
          page: currentPage,
          itemsPerPage: itemsPerPage,
          sortDesc: [true],
          mustSort: true,
        },
        headers: [
          {
            text: this.$i18n.t('views.external_catalog.providers.headers.name'),
            value: 'name',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.external_catalog.providers.headers.source'),
            value: 'source',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.external_catalog.providers.headers.store'),
            value: 'store',
            sortable: false,
          },
        ],
        footerProps: {
          'items-per-page-options': [itemsPerPage],
          'show-first-last-page': true,
          'show-current-page': true,
        },
      },
      result: {
        items: [],
        count: 0,
        loading: false,
      },
    }
  },
  computed: {
    queryString: function () {
      return 'external_catalog/providers?' + getQueryString(
        this.table.options.page,
        {},
        this.table.options.sortBy,
        this.table.options.sortDesc
      )
    },
  },
  watch: {
    filters() {
      this.table.options.page = 0
    },
    queryString: {
      handler() {
        this.load()
      },
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }

      this.result.loading = true
      this.result.items = []
      this.result.count = 0

      this.$axios.get(this.queryString, {
        cancelToken: axiosSource.token,
      })
        .then((response) => {
          this.result.items = response.data['hydra:member']
          this.result.count = response.data['hydra:totalItems']
        })
        .catch((error) => {
          if (!this.$axios.isCancel(error)) {
            // eslint-disable-next-line no-console
            console.log(error)
            this.result.loading = false
          }
        })
        .finally(() => {
          this.request = null
          this.result.loading = false
        })
    },
    openCreationModal() {
      this.modal.opened = true
    },
  },
}
</script>

<style scoped>
</style>
