<template>
  <v-container
    id="translations-list"
    tag="section"
    fluid
  >
    <Filters
      :add-modal-opened.sync="modal.add.opened"
      @onFiltersChanged="onFiltersChanged"
    />
    <v-sheet
      elevation="3"
      rounded
    >
      <v-data-table
        :footer-props="table.footerProps"
        :headers="table.headers"
        :items="result.items"
        :loading="result.loading"
        :options.sync="table.options"
        :page="table.options.page"
        :server-items-length="result.count"
        @update:page="load"
        @update:sort-by="load"
        @update:sort-desc="load"
      >
        <template v-slot:loading>
          <v-sheet
            class="text-center pa-6"
          >
            {{ $t('global.loading') }}
          </v-sheet>
        </template>
        <template v-slot:no-data>
          <v-sheet
            class="text-center pa-6"
          >
            {{ $t('global.no-data') }}
          </v-sheet>
        </template>
        <template v-slot:item.key="{ item }">
          {{ item.key }}
        </template>
        <template v-slot:item.locale="{ item }">
          {{ item.locale }}
        </template>
        <template v-slot:item.value="{ item }">
          <div v-if="!editChangeValues[item.id]">
            <div @click="editChangeValues[item.id] = item.value">
              {{ item.value }}
            </div>
          </div>
          <div v-else>
            <v-row style="max-width: 500px">
              <v-col>
                <v-text-field
                  :value="item.value"
                  dense
                  hide-details
                  cols="3"
                  @change="$event => editChangeValues[item.id] = $event"
                />
              </v-col>
              <v-col>
                <v-btn
                  icon
                  target="_blank"
                  @click="changeValue(item)"
                >
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </template>
        <template v-slot:item.action="{ item }">
          <div style="white-space: nowrap;">
            <v-btn
              icon
              @click="deleteLine(item)"
            >
              <v-icon
                color="blue lighten-1"
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-sheet>
    <CreationModal
      :opened.sync="modal.add.opened"
      @submited="add"
    />
    <ConfirmModal ref="confirm" />
  </v-container>
</template>

<script>
import CreationModal from '@/views/Admin/Translations/CreationModal.vue'
import Filters from '@/views/Admin/Translations/Filters.vue'
import { getQueryString } from '@/helpers/queryString'
import snackbarMixin from '@/mixins/snackbar'

export default {
  name: 'TranslationsList',
  components: { CreationModal, Filters },
  mixins: [snackbarMixin],
  data() {
    const itemsPerPage = 30
    const currentPage = +this.$route.query.page || 1
    
    return {
      request: null,
      filters: {
        key_start: this.$route.query.key_start,
      },
      modal: {
        add: {
          opened: false,
        },
      },
      table: {
        options: {
          page: currentPage,
          itemsPerPage: itemsPerPage,
          sortDesc: [true],
          mustSort: true,
        },
        headers: [
          {
            text: 'Clé',
            value: 'key',
            sortable: false,
            width: '30%',
          },
          {
            text: 'Locale',
            value: 'locale',
            sortable: false,
          },
          {
            text: 'Text',
            value: 'value',
            sortable: false,
            width: '30%',
          },
          {
            text: '',
            value: 'action',
            sortable: false,
          },
        ],
        footerProps: {
          'items-per-page-options': [itemsPerPage],
          'show-first-last-page': true,
          'show-current-page': true,
        },
      },
      result: {
        items: [],
        count: 0,
        loading: false,
      },
      editChangeValues: {},
    }
  },
  computed: {
    queryString: function () {
      return 'i18n/translations?' + getQueryString(
        this.table.options.page,
        this.filters,
        this.table.options.sortBy,
        this.table.options.sortDesc
      )
    },
  },
  watch: {
    queryString: {
      handler() {
        this.load()
      },
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    onFiltersChanged(filters) {
      this.filters = filters
      this.$router.replace({ name: 'AdminTranslationsList', query:
          { ...this.filters, page : this.table.options.page } })
    },
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }

      this.result.loading = true
      this.result.items = []
      this.result.count = 0

      this.$axios.get(this.queryString, {
        cancelToken: axiosSource.token,
      })
        .then((response) => {
          this.result.items = response.data['hydra:member']
          this.result.count = response.data['hydra:totalItems']

          const editChangeValues = {}
          for (const item of this.result.items) {
            editChangeValues[item.id] = false
          }
          this.editChangeValues = editChangeValues
        })
        .catch((error) => {
          if (!this.$axios.isCancel(error)) {
            // eslint-disable-next-line no-console
            console.log(error)
            this.result.loading = false
          }
        })
        .finally(() => {
          this.request = null
          this.result.loading = false
        })
    },
    async add(item) {
      this.table.loading = true

      this.cancel()

      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }

      try {
        await this.$axios.post('i18n/translations', {
          key: item.key,
          locale: item.locale,
          value: item.value,
        }, {
          cancelToken: axiosSource.token,
        })

        this.snackbarSuccess('success')
        this.load()
      } catch (error) {
        this.snackbarError('error')
        if (!this.$axios.isCancel(error)) {
          // eslint-disable-next-line no-console
          console.log(error)
          this.table.loading = false
        }
      } finally {
        this.request = null
      }
    },
    async deleteLine(item) {
      this.table.loading = true

      if (await this.$refs.confirm.open(
        'Suppression',
        'Êtes-vous sûr de vouloir supprimer cette ligne ?')
      ) {
        this.cancel()

        let axiosSource = this.$axios.CancelToken.source()
        this.request = { cancel: axiosSource.cancel }

        try {
          await this.$axios.delete('i18n/translations/' + item.id, {}, {
            cancelToken: axiosSource.token,
          })
          this.snackbarSuccess('success')
          this.load()
        } catch (error) {
          this.snackbarError('error')
          if (!this.$axios.isCancel(error)) {
            // eslint-disable-next-line no-console
            console.log(error)
            this.table.loading = false
          }
        } finally {
          this.request = null
        }
      } else {
        this.table.loading = false
      }
    },
    async changeValue(item) {

      const value = '' + this.editChangeValues[item.id]

      if (!value || value === item.value) {
        this.editChangeValues[item.id]  = false

        return
      }

      this.table.loading = true

      this.cancel()

      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }

      try {
        await this.$axios.post('i18n/translations/' + item.id + '/change_value', { value }, {
          cancelToken: axiosSource.token,
        })
        this.snackbarSuccess('success')
        for (const currentItem of this.result.items) {
          if (currentItem.id === item.id) {
            currentItem.value = value
          }
        }
      } catch (error) {
        this.snackbarError('error')
        if (!this.$axios.isCancel(error)) {
          // eslint-disable-next-line no-console
          console.log(error)
          this.table.loading = false
        }
      } finally {
        this.request = null
        this.editChangeValues[item.id]  = false
      }
    },
  },
}
</script>

<style scoped>
</style>
