<template>
  <Page>
    <template v-slot:toolbar-buttons>
      <ButtonLink
        :href="'/admin/tasks/list/?context=' + encodeURIComponent('gid://ExternalCatalog/SynchroLine')"
        icon="mdi-server"
        text="Tasks"
      />
    </template>
    <template v-slot:content>
      <MultiSelection
        :table="table"
        :result="result"
        :fetch-all-items="fetchAllSynchroLineIds"
        :current-items="result.items"
        :total-items="result.count"
      >
        <template #filters="{ resetSelection }">
          <Filters
            :initial-filters="initialFilters"
            @onFiltersChanged="e => onFiltersChanged(e, resetSelection)"
          />
        </template>
        <template #item="{ item }">
          <div class="image-wrapper white--text align-end">
            <v-carousel hide-delimiters>
              <template v-if="item.external_product.images.length">
                <v-carousel-item
                  v-for="(image, index) in item.external_product.images"
                  :key="item.id + '-img' + index"
                  :src="image"
                  cover
                />
              </template>
              <template v-else>
                <v-carousel-item
                  :key="item.id + '-img-default'"
                  src="../../../assets/no-image.webp"
                  cover
                />
              </template>
            </v-carousel>
            <v-card-title class="product-title font-weight-bold">
              {{ item.external_product.title.fr }}
            </v-card-title>
          </div>
          <v-divider />
          <v-card-subtitle>
            <v-row align="center">
              <v-col
                cols="12"
                md="6"
                sm="12"
                xs="12"
              >
                <v-chip
                  v-if="item.import_status !== 'none'"
                  :color="statusColor(item)"
                  text-color="white"
                  class="ma-2"
                  label
                  small
                >
                  {{
                    $t(
                      'views.external_catalog.synchro_lines.forms.labels.import',
                      {
                        'status': $t(
                          `views.external_catalog.synchro_lines.enum.import_status.${item.import_status}`
                        )
                      }
                    )
                  }}
                </v-chip>
                <v-icon
                  v-if="item.processing"
                  color="green darken-2"
                  :title="$t('views.external_catalog.synchro_lines.forms.labels.processing')"
                >
                  mdi-sync
                </v-icon>
                <v-icon
                  v-else-if="item.dirty && item.import_status !== 'none'"
                  color="orange darken-2"
                  :title="$t('views.external_catalog.synchro_lines.forms.labels.dirty')"
                >
                  mdi-sync-alert
                </v-icon>
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="12"
                xs="12"
              >
                <div class="text-right">
                  <span
                    v-if="item.external_product.nb_variants === 1"
                    class="text-h6"
                  >
                    {{ item.external_product.price.min|currency }}
                  </span>
                  <template v-else>
                    À partir de <span class="text-h6">{{ item.external_product.price.min|currency }}</span>
                  </template>
                </div>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-text>
            <v-simple-table dense>
              <tbody>
                <tr v-if="item.external_product.tags.length">
                  <td>{{ $t('views.external_catalog.synchro_lines.forms.labels.tags') }}</td>
                  <td>{{ item.external_product.tags.join(', ') }}</td>
                </tr>
                <tr>
                  <td>{{ $t('views.external_catalog.synchro_lines.forms.labels.reference') }}</td>
                  <td>{{ item.external_product.reference }}</td>
                </tr>
                <tr>
                  <td>{{ $t('views.external_catalog.synchro_lines.forms.labels.provider') }}</td>
                  <td>{{ item.provider.name }}</td>
                </tr>
                <tr>
                  <td>{{ $t('views.external_catalog.synchro_lines.forms.labels.variants') }}</td>
                  <td>{{ item.external_product.nb_variants }}</td>
                </tr>
                <tr>
                  <td>{{ $t('views.external_catalog.synchro_lines.forms.labels.quantity') }}</td>
                  <td>{{ item.external_product.quantity }}</td>
                </tr>
                <tr>
                  <td>{{ $t('views.external_catalog.synchro_lines.forms.labels.category') }}</td>
                  <td>
                    <MappedValue :mapped-data="resolveCategory(item)" />
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('views.external_catalog.synchro_lines.forms.labels.sizes') }}</td>
                  <td>
                    <MappedValue
                      v-for="(data, index) in resolveAttributes(item, 'size')"
                      :key="index"
                      :mapped-data="data"
                    />
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('views.external_catalog.synchro_lines.forms.labels.brand') }}</td>
                  <td>
                    <MappedValue
                      v-for="(data, index) in resolveAttributes(item, 'brand')"
                      :key="index"
                      :mapped-data="data"
                    />
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('views.external_catalog.synchro_lines.forms.labels.condition') }}</td>
                  <td>
                    <MappedValue
                      v-for="(data, index) in resolveAttributes(item, 'state')"
                      :key="index"
                      :mapped-data="data"
                    />
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('views.external_catalog.synchro_lines.forms.labels.color') }}</td>
                  <td>
                    <MappedValue
                      v-for="(data, index) in resolveAttributes(item, 'color')"
                      :key="index"
                      :mapped-data="data"
                    />
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('views.external_catalog.synchro_lines.forms.labels.material') }}</td>
                  <td>
                    <MappedValue
                      v-for="(data, index) in resolveAttributes(item, 'material')"
                      :key="index"
                      :mapped-data="data"
                    />
                  </td>
                </tr>
                <tr v-show="showAdvancedDescription(item.id)">
                  <td>
                    {{ $t('views.external_catalog.synchro_lines.forms.labels.title') }}
                  </td>
                  <td class="long-text">
                    {{ item.external_product.title }}
                  </td>
                </tr>
                <tr v-show="showAdvancedDescription(item.id)">
                  <td>
                    {{ $t('views.external_catalog.synchro_lines.forms.labels.description') }}
                  </td>
                  <td class="long-text">
                    {{ item.external_product.description }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-row>
              <v-col class="text-right">
                <a
                  href="#"
                  @click.prevent="toggleAdvancedDescription(item.id)"
                >
                  <template v-if="!showAdvancedDescription(item.id)">
                    {{ $t('views.external_catalog.synchro_lines.forms.labels.more') }}
                  </template>
                  <template v-else>
                    {{ $t('views.external_catalog.synchro_lines.forms.labels.less') }}
                  </template>
                </a>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
        <template #action="{ nbSelectedItems, resolveSelectedItems, resetSelection }">
          <DeleteModal
            :opened.sync="modal.delete.opened"
            :resolve-selected-items="resolveSelectedItems"
            :nb-selected-items="nbSelectedItems"
            @success="resetSelection"
          />
          <v-btn
            class="white--text mr-6"
            color="red darken-3"
            @click="() => modal.delete.opened = true"
          >
            {{ $t('views.external_catalog.synchro_lines.forms.input.delete') }}
          </v-btn>

          <ImportModal
            :opened.sync="modal.import.opened"
            :resolve-selected-items="resolveSelectedItems"
            :nb-selected-items="nbSelectedItems"
            :tag-prefix="importTagPrefix"
            @success="resetSelection"
          />
          <v-btn
            class="white--text"
            color="blue darken-3"
            @click="() => modal.import.opened = true"
          >
            {{ $t('views.external_catalog.synchro_lines.forms.input.import') }}
          </v-btn>
        </template>
      </MultiSelection>
    </template>
  </Page>
</template>

<script>
import ButtonLink from '@/components/base/Toolbar/ButtonLink.vue'
import DeleteModal from '@/views/ExternalCatalog/SynchroLines/Modal/Delete.vue'
import { fetchAllHydra } from '@/helpers/rest'
import Filters from '@/views/ExternalCatalog/SynchroLines/Filters.vue'
import { getQueryString } from '@/helpers/queryString'
import ImportModal from '@/views/ExternalCatalog/SynchroLines/Modal/Import.vue'
import MappedValue from '@/views/ExternalCatalog/SynchroLines/MappedValue.vue'
import MultiSelection from '@/components/core/MultiSelection.vue'
import Page from '@/components/core/Page.vue'
import snackbarMixin from '@/mixins/snackbar'
import taskMixin from '@/mixins/task'

const itemsPerPage = 30

export default {
  name: 'ExternalCatalogSynchroLinesList',
  components: { DeleteModal, ImportModal, ButtonLink, MappedValue, Page, Filters, MultiSelection },
  mixins: [snackbarMixin, taskMixin],
  data() {
    const initialFilters = {
      provider: this.$route.query.provider,
      query: this.$route.query.query,
      'store[]': [].concat(this.$route.query['store[]']),
      'category[]': [].concat(this.$route.query['category[]']),
      import_status: this.$route.query.import_status,
      'brand[]': [].concat(this.$route.query['brand[]']),
      condition: this.$route.query.condition,
      'price[min]': this.$route.query['price[min]'],
      'price[max]': this.$route.query['price[max]'],
      stock_only: this.$route.query.stock_only === 'true',
    }

    return {
      request: null,
      deleteDialog: false,
      table: {
        options: {
          page: +this.$route.query.page || 1,
          itemsPerPage: itemsPerPage,
          sortBy: [],
          sortDesc: [],
        },
        footerProps: {
          'items-per-page-options': [30],
          'show-first-last-page': true,
          'show-current-page': true,
        },
      },
      filters: initialFilters,
      initialFilters: initialFilters,
      advancedDescription: {},
      result: {
        items: [],
        count: 0,
        loading: false,
      },
      modal: {
        delete: {
          opened: false,
        },
        import: {
          opened: false,
        },
      },
    }
  },
  computed: {
    queryString: function () {
      let filters = {
        ...this.filters,
        'quantity[min]': this.filters.stock_only ? 1 : 0,
      }
      delete filters.stock_only

      return 'external_catalog/synchro_lines?' + getQueryString(
        this.table.options.page,
        filters,
        this.table.options.sortBy,
        this.table.options.sortDesc,
        this.table.options.itemsPerPage
      )
    },
    importTagPrefix: function () {
      const parts = ['Export']
      if (this.filters.provider) {
        parts.push(this.filters.provider)
      }

      return parts.join(' ')
    },
  },
  watch: {
    filters() {
      this.table.options.page = 0
    },
    queryString: {
      handler() {
        this.load()
      },
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    async fetchAllSynchroLineIds() {
      const [basePath, queryString] = this.queryString.split('?', 2)
      const searchParams = new URLSearchParams(queryString)
      searchParams.set('page', '0')
      searchParams.set('itemsPerPage', '1000')
      searchParams.set('properties[]', 'id')

      return (await fetchAllHydra(this.$axios, basePath + '?' + searchParams.toString())).map(item => item.id)
    },
    showAdvancedDescription(itemId) {
      return !!this.advancedDescription[itemId]
    },
    toggleAdvancedDescription(itemId) {
      this.$set(this.advancedDescription, itemId,  !this.advancedDescription[itemId])
    },
    onFiltersChanged(filters, resetSelection) {
      resetSelection()
      this.filters = filters
      this.$router.replace({ name: 'ExternalCatalogSynchroLinesList', query: this.filters })
    },
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      
      this.result.loading = true
      this.result.items = []
      this.result.count = 0

      this.$axios.get(this.queryString, {
        cancelToken: axiosSource.token,
      })
        .then((response) => {
          const items = response.data['hydra:member']
          items.map(function(item) {
            item.external_product.attributes = [
              ...item.external_product.attributes,
              ...item.external_product.variants.map(v => v.sizeAttributes).flat(1),
            ]
            const mappings = {
              taxonomy: {},
              category: {},
            }

            for (const mapping of item.mapping) {
              if (null === mapping.classification && null === mappings[mapping.type][mapping.external_value]) {
                continue
              }
              mappings[mapping.type][mapping.external_value] = mapping.classification.internal_name
            }
            item.mapping = mappings
          })
          this.result.items = items
          this.result.count = response.data['hydra:totalItems']
        })
        .catch((error) => {
          this.result.loading = false
          // eslint-disable-next-line no-console
          console.log(error)
        })
        .finally(() => {
          this.result.loading = false
          this.request = null
        })
    },
    statusColor(item) {
      switch (item.import_status) {
      case 'pending':
        return 'teal lighten-2'
      case 'done':
        return 'light-green darken-3'
      case 'error':
        return 'red lighten-3'
      case 'none':
      default:
        return 'grey lighten-1'
      }
    },
    resolveAttributes(item, kind) {
      return item.external_product.attributes
        .filter(a => a.kind === kind)
        .map(a => {
          let name
          let mappedFrom = null
          let isNew = false
          let isRequired = false

          if (item.mapping.taxonomy[a.key]) {
            mappedFrom = a.key
            name = item.mapping.taxonomy[a.key]
          } else {
            name = a.name

            if (!name) {
              name = a.key
              isRequired = true
            } else {
              isNew = true
            }
          }

          return {
            name,
            mappedFrom,
            isNew,
            isRequired,
          }
        })
    },
    resolveCategory(item) {
      let name = item.external_product.category
      let mappedFrom = null
      let isRequired = false

      if (item.mapping.category[item.external_product.category]) {
        name = item.mapping.category[item.external_product.category]
        mappedFrom = item.external_product.category
      } else {
        isRequired = true
      }

      return {
        name,
        mappedFrom,
        isNew: false,
        isRequired,
      }
    },
  },
}
</script>

<style scoped>
.image-wrapper {
  position: relative;
}
.product-title {
  background-color: rgba(0,0,0,0.4);
  bottom: 0;
  position: absolute;
  width: 100%;
}

.long-text {
  max-width: 5px; /* arbitrary value to force width calculation */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.long-text:hover {
  cursor: pointer;
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}

</style>
