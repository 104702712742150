<template>
  <Page
    :mounted.sync="mounted"
  >
    <template v-slot:title>
      <v-icon class="mr-2">
        mdi-plus-box
      </v-icon>
      Ajout d'un produit
    </template>

    <template v-slot:content>
      <v-card
        v-if="mounted"
        color="transparent"
        flat
      >
        <v-overlay
          :value="loading"
        >
          <v-progress-circular
            indeterminate
            size="64"
          />
        </v-overlay>
        <v-card-text>
          <v-form ref="form">
            <ProductFeaturesForm
              :form.sync="form"
              :errors.sync="errors"
              show-title
            />

            <ProductPhotoForm
              v-model="form.photos"
              :error.sync="errors.photos"
              show-title
            />
        
            <ProductContentForm
              :title.sync="form.title"
              :description.sync="form.description"
              :defects.sync="form.defects"
              :sizing.sync="form.sizing"
              :translations.sync="form.translations"
              :errors.sync="errors"
              show-title
            />
          
            <ProductVariantsForm
              v-model="form.variants"
              :category-id="form.categoryId"
              :target="form.target"
              :errors.sync="errors.variants"
              show-title
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="save"
          >
            {{ $t('forms.buttons.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </Page>
</template>

<script>
import inputMixin from '@/mixins/input'
import Page from '@/components/core/Page.vue'
import ProductContentForm from '@/components/base/Product/ContentForm'
import ProductFeaturesForm from '@/components/base/Product/FeaturesForm'
import productFormMixin from '@/mixins/productForm'
import ProductPhotoForm from '@/components/base/Product/PhotoForm'
import ProductVariantsForm from '@/components/base/Product/VariantsForm'
import snackbarMixin from '@/mixins/snackbar'

export default {
  name: 'ProductsAdd',
  components: {
    Page,
    ProductFeaturesForm,
    ProductPhotoForm,
    ProductVariantsForm,
    ProductContentForm,
  },
  mixins: [snackbarMixin, inputMixin, productFormMixin],
  data() {
    return {
      mounted: false,
      loading: false,
      loadingText: '',
      errors: {},
      form: {},
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler() {
        this.init()
      },
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.errors = {
        categoryId: null,
        family: null,
        storeId: null,
        weight: null,
        title: null,
        description: null,
        defects: null,
        sizing: null,
        translations: [],
        photos: null,
        variants: [],
        target: null,
      }
      
      if (this.$route.query.fromId) {
        this.load(this.$route.query.fromId)
      } else {
        this.form = {
          target: null,
          categoryId: null,
          family: null,
          attributes: [],
          storeId: null,
          weight: null,
          title: null,
          dropIds: [],
          tagIds: [],
          translations: [],
          description: null,
          defects: null,
          sizing: null,
          photos: [],
          variants: [],
        }
        this.mounted = true
      }
    },
    async load(id) {
      this.loadingText = this.$i18n.t('views.products.forms.messages.load.progress')
      this.loading = true

      try {
        const response = await this.$axios.get('v3/products/' + id)
        const product = response.data
        this.form = {
          storeId: product.store.id,
          categoryId: product.category?.id,
          family: product.family,
          attributes: product.attributes,
          dropIds: product.drop_ids,
          tagIds: product.tags.map(t => t.id),
          salePointId: product.sale_point?.id,
          target: product.target,
          weight: product.weight,
          title: product.title,
          description: product.description,
          defects: product.defects,
          sizing: product.sizing,
          translations: product.translations.map((translation) => ({
            language: translation.language,
            title: translation.title,
            description: translation.description,
            defect: translation.defect,
            sizing: translation.sizing,
          })) ?? [],
          score: product.score,
          favorite: product.favorite,
          wornPhotos: product.worn_photos,
          inImparfaiteWarehouse: product.in_imparfaite_warehouse,
          photos: product.photos.map((photo) => ({
            id: photo.photo.id,
            isDeleted: photo.deleted,
            photo: {
              src: photo.photo.src,
              kind: photo.photo.kind,
              state: photo.photo.state,
            },
          })),
          variants: product.variants.map((variant) => ({
            id: null,
            externalReference: null,
            eanCode: null,
            sizes: variant.size_attributes,
            stock: 0,
            basePrice: variant.base_price,
            price: variant.price,
            discountRate: variant.discount_rate,
            sku: null,
            measures: variant.measures.map((measure) => ({
              value: measure.value ? parseFloat(measure.value) : null,
              slug: measure.kind,
            })),
          })),
        }
      } catch (error) {
        const error_message = error?.response?.data['hydra:description'] ??
          error?.response?.data['detail'] ??
          'No context'
        this.snackbarError(this.$i18n.t('views.curation.forms.messages.load.failed', { error: error_message }))
      } finally {
        this.loading = false
        this.mounted = true
      }
    },
    async save() {
      this.loadingText = this.$i18n.t('views.products.forms.messages.save.progress')
      this.loading = true

      const product = {
        title: this.form.title,
        description: this.form.description,
        translations: this.form.translations.map(v => ({
          language: v.language,
          title: v.title,
          description: v.description,
          defect: v.defect,
          sizing: v.sizing,
        })),
        photos: this.form.photos.map((photo) => {
          return {
            id: photo.id,
            kind: photo.photo.kind,
          }
        }),
        category: this.form.categoryId,
        defects: this.form.defects,
        sizing: this.form.sizing,
        store: this.form.storeId,
        target: this.form.target,
        variants: this.form.variants.map(v => ({
          external_reference: v.externalReference,
          ean_code: v.eanCode,
          sizes: v.sizes.map((attribute) => ({
            name: attribute.name,
            kind: attribute.kind,
            taxonomy_id: attribute.taxonomy_id,
          })),
          stock: v.stock,
          base_price: v.basePrice,
          price: v.price,
          measures: v.measures.map(m => ({
            kind: m.slug,
            value: m.value,
          })),
        })),
        family: this.form.family,
        attributes: this.form.attributes.map((attribute) => ({
          name: attribute.name,
          kind: attribute.kind,
          taxonomy_id: attribute.taxonomy_id,
        })),
        drops: this.form.dropIds,
        tags: this.form.tagIds,
        weight: this.form.weight,
      }

      try {
        this.loading = true
        const response = await this.$axios.post('v3/products', product)
        this.snackbarSuccess(this.$t('views.products.forms.messages.add.succeed'),
          '/products/' + response?.data?.id)
        this.$vuetify.goTo(0)
        this.init()
      } catch (error) {
        if (error?.response?.data['@type'] === 'hydra:Error') {
          this.snackbarError(
            this.$t('views.products.forms.messages.add.failed', {
              error: error?.response?.data['hydra:description'] ?? 'internal',
            })
          )

          return
        }
        if (error?.response?.data['@type'] !== 'ConstraintViolationList') {
          this.snackbarError(this.$t('views.products.forms.messages.add.failed', { error: 'internal' }))

          return
        }
        this.apiViolationToErrors(error?.response?.data?.violations ?? [])
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
