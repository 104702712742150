<template>
  <v-dialog
    :value="openedValue"
    max-width="700px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card>
      <v-card-title />
      <v-card-text>
        <v-row>
          <v-col>
            <v-alert
              type="warning"
              prominent
              dense
            >
              {{
                $t('views.external_catalog.synchro_lines.forms.dialog.deletion.text', {
                  'selected': nbSelectedItems
                })
              }}
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          color="red darken-2"
          @click="close()"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>
          {{ $t('views.external_catalog.synchro_lines.forms.dialog.deletion.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          :disabled="loading"
          raised
          color="red darken-1"
          class="white--text"
          @click="submit()"
        >
          <v-icon left>
            mdi-delete
          </v-icon>
          {{ $t('views.external_catalog.synchro_lines.forms.dialog.deletion.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import taskMixin from '@/mixins/task'

export default {
  name: 'SynchroLinesDeleteModal',
  mixins: [taskMixin],
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    resolveSelectedItems: {
      type: Function,
      default: () => [],
    },
    nbSelectedItems: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      directToCuration: false,
      tagName: null,
    }
  },
  computed: {
    openedValue: {
      get() {
        return this.opened
      },
      set(value) {
        this.$emit('update:opened', value)
      },
    },
  },
  methods: {
    close() {
      this.loading = false
      this.openedValue = false
    },
    async submit() {
      this.loading = true

      try {
        const response = await this.$axios.post(
          'external_catalog/synchro_lines/delete',
          { ids: await this.resolveSelectedItems() }
        )

        this.taskCreated(response.data.id)
        this.$emit('success')
        this.close()

      } catch (error) {
        this.taskFailed(error.response.data['hydra:description'] ?? error.response.data['detail'] ?? null)
        this.loading = false
      }
    },
  },
}
</script>
