<template>
  <div>
    <slot
      name="filters"
      :reset-selection="resetSelection"
    />
    <v-sheet
      v-if="result.count"
      elevation="3"
      rounded
      class="mb-4"
    >
      <v-container fluid>
        <v-row dense>
          <v-col cols="12">
            <v-checkbox
              v-model="selectAll"
              :label="$t('multi_selection.select_all')"
              dense
              hide-details
              @change="checkSelectAll($event)"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-alert
              v-if="nbSelectedItems > 0"
              type="info"
              class="mt-6"
            >
              <v-row>
                <v-col>
                  {{
                    $t(
                      'multi_selection.selected',
                      {
                        'selected': nbSelectedItems,
                        'total': result.count,
                      }
                    )
                  }}
                </v-col>
                <v-col class="d-flex">
                  <v-spacer />
                  <slot
                    name="action"
                    :nb-selected-items="nbSelectedItems"
                    :resolve-selected-items="getSelectedItems"
                    :reset-selection="resetSelection"
                  />
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-data-iterator
      :footer-props="table.footerProps"
      :items="result.items"
      :loading="result.loading"
      :options.sync="table.options"
      :server-items-length="result.count"
    >
      <template v-slot:loading>
        <v-sheet
          class="text-center pa-6"
          elevation="3"
          rounded
        >
          <div class="text-center mb-4">
            <v-progress-linear
              indeterminate
              rounded
              height="6"
            />
          </div>
          {{ $t('global.loading') }}
        </v-sheet>
      </template>
      <template v-slot:no-data>
        <v-sheet class="text-center pa-6">
          {{ $t('global.no-data') }}
        </v-sheet>
      </template>
      <template v-slot="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.id"
            :cols="colInfo.cols"
            :sm="colInfo.sm"
            :md="colInfo.md"
            :lg="colInfo.lg"
            :xl="colInfo.xl"
          >
            <v-card>
              <v-card-actions>
                <v-checkbox
                  v-model="selectionOverriden[item.id]"
                  dense
                  hide-details
                />
              </v-card-actions>
              <slot
                name="item"
                :item="item"
              />
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
export default {
  name: 'MultiSelection',
  props: {
    fetchAllItems: {
      type: Function,
      required: true,
    },
    result: {
      type: Object,
      default: () => [],
    },
    table: {
      type: Object,
      default: () => {},
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      checkItems: [],
      selectAll: false,
      selectionOverriden: { },
    }
  },
  computed: {
    nbSelectedItems: function() {
      if (!this.selectAll) {
        return Object.values(this.selectionOverriden).filter(v => !!v).length
      } else {
        return this.result.count - Object.values(this.selectionOverriden).filter(v => !v).length
      }
    },
    colInfo: function() {
      if (this.small) {
        return {
          cols: 12,
          sm: 6,
          md: 4,
          lg: 4,
          xl: 2,
        }
      }

      return {
        cols: 12,
        sm: 6,
        md: 4,
        lg: 3,
        xl: 2,
      }
    },
  },
  watch: {
    'result.items' () {
      if (this.selectAll) {
        const newSelectionOverriden = {}
        for (const p in this.selectionOverriden) {
          if (this.selectionOverriden[p] === false) {
            newSelectionOverriden[p] = false
          }
        }
        for (const item of this.result.items) {
          if (typeof newSelectionOverriden[item.id] === 'undefined') {
            newSelectionOverriden[item.id] = true
          }
        }
        this.selectionOverriden = newSelectionOverriden
      }
    },
  },
  methods: {
    checkSelectAll(checked) {
      this.selectAll = checked
      this.selectionOverriden = {}
      if (checked) {
        const tmp = {}
        for (const item of this.result.items) {
          tmp[item.id] = true
        }
        this.selectionOverriden = tmp
      }
    },
    resetSelection() {
      this.selectAll = false
      this.selectionOverriden = {}
    },
    async getSelectedItems() {
      let selectedItems = []
      if (!this.selectAll) {
        selectedItems = Object.keys(this.selectionOverriden)
          .filter(key => this.selectionOverriden[key] === true)
      } else {
        const itemsToExclude = Object.keys(this.selectionOverriden)
          .filter(key => this.selectionOverriden[key] === false)

        const allItem = await this.fetchAllItems()
        selectedItems = allItem.filter(v => !itemsToExclude.includes(v))
      }

      return selectedItems
    },

  },
}
</script>
