<template>
  <v-container
    tag="section"
    fluid
  >
    <v-form @submit.prevent="upload">
      <v-row>
        <v-col
          cols="12"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="query"
            :prepend-icon="null"
            prepend-inner-icon="mdi-selection-search"
            placeholder="Paramètres de recherche"
            label="Paramètres de recherche"
            outlined
            hide-details
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-spacer />
        <v-col
          md="6"
          align="right"
        >
          <v-btn
            type="submit"
            color="primary"
          >
            Importer
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import taskMixin from '@/mixins/task'

export default {
  name: 'ExternalCatalogSyncFromShopify',
  mixins: [ taskMixin ],
  props: {
    provider: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      query: null,
      loading: false,
    }
  },
  methods: {
    async upload() {
      this.loading = true

      try {
        const response = await this.$axios.post('external_catalog/synchro_tasks/from_shopify', {
          provider: this.provider,
          query: this.query,
        })

        this.taskCreated(response.data.id)
      } catch (error) {
        this.taskFailed(error.response.data['hydra:description'] ?? error.response.data['detail'] ?? null)
      } finally {
        this.query = null
      }
    },
  },
}
</script>
