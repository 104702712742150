<template>
  <v-sheet
    elevation="3"
    rounded
    class="mb-4"
  >
    <v-container
      fluid
    >
      <v-row
        dense
      >
        <v-col
          cols="12"
          md="3"
          lg="3"
        >
          <SourceSelector v-model="filters.source" />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="3"
        >
          <ClassificationTypeSelector v-model="filters.type" />
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import ClassificationTypeSelector from '@/components/base/ExternalCatalog/Input/ClassificationTypeSelector.vue'
import { debounce } from '@/helpers/debounce'
import SourceSelector from '@/components/base/ExternalCatalog/Input/SourceSelector.vue'

export default {
  name: 'ExternalCatalogClassificationFilters',
  components: { ClassificationTypeSelector, SourceSelector },
  props: {
    addModalOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filters: {
        source: this.$route.query.source,
        type: this.$route.query.type,
      },
    }
  },
  watch: {
    filters: {
      handler: debounce(function (value) {
        this.$emit('onFiltersChanged', value)
      }, 500),
      deep: true,
    },
  },
}
</script>
