<template>
  <v-card
    class="mb-4"
  >
    <v-card-title v-if="showTitle">
      {{ $t('views.products.forms.titles.photos') }}
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <draggable
          v-model="photos"
          class="row"
          :sort="true"
          handle=".handle"
        >
          <v-col
            v-for="(photo, index) in photos"
            :key="photo.id"
            style="max-width: 220px; margin: auto"
          >
            <v-card>
              <v-card-text>
                <v-img
                  :src="photo.photo.src.thumbnail"
                  :class="{
                    'photo-state-deleted': photo.isDeleted,
                  }"
                />
              </v-card-text>
              <v-card-actions>
                <v-icon class="handle">
                  mdi-cursor-pointer
                </v-icon>
                <v-spacer />
                <v-icon @click="zoom(photo.id)">
                  mdi-magnify-plus-outline
                </v-icon>
                <v-menu
                  bottom
                  left
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="deletePhoto(index)">
                      <v-list-item-icon>
                        <v-icon color="red darken-2">
                          mdi-delete
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('forms.buttons.delete') }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-actions>
            </v-card>
          </v-col>
        </draggable>
      </v-row>
      <v-divider class="my-4" />
      <v-row
        dense
      >
        <v-col
          cols="12"
          md="4"
          sm="12"
        >
          <v-file-input
            :key="uploadKey"
            accept="image/*"
            :prepend-icon="null"
            prepend-inner-icon="mdi-camera"
            outlined
            hide-details
            dense
            multiple
            :placeholder="$t('views.products.forms.input.photo_placeholder')"
            :label="$t('views.products.forms.input.photo_upload')"
            @change="uploadPhotos($event); $emit('update:error', null)"
          />
        </v-col>
      </v-row>
      <div
        v-if="error"
        class="red--text"
        style="font-size: 12px"
      >
        {{ error }}
      </div>
      <ProductPhotoGalleryDialog
        :show.sync="zoomShow"
        :product-photos="photos"
        :default-photo-id="zoomPhotoId"
        @close="zoomShow = false"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import draggable from 'vuedraggable'
import ProductPhotoGalleryDialog from '@/components/core/ProductPhotoGalleryDialog'
import snackbarMixin from '@/mixins/snackbar'

export default {
  components: { draggable, ProductPhotoGalleryDialog },
  mixins: [ snackbarMixin ],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: null,
    },
    showTitle: Boolean,
  },
  data() {
    return {
      uploadKey: 0,
      zoomShow: false,
      zoomPhotoId: null,
    }
  },
  computed: {
    photos: {
      get () { return this.value },
      set (value) {
        let index = 0
        value = value.map(photo => ({ ... photo, photo: { ...photo.photo, kind: index++ === 0 ? 'FRONT' : 'DETAIL' } }))
        this.$emit('input', value)
      },
    },
  },
  methods: {
    uploadPhotos(files) {
      const promises = []
      this.loadingText = this.$i18n.t('views.products.forms.messages.photos.uploading')
      this.loading = true

      files.forEach((file) => {
        let formData = new FormData()
        formData.append('image', file)
        promises.push(this.$axios.post('photos', formData, { headers: { 'Content-Type': 'multipart/form-data' } }))
      })

      return Promise
        .allSettled(promises)
        .then((responses) => {
          const photos = [ ...this.photos]
          responses.forEach((response) => {
            if (response.status === 'fulfilled') {
              let photo = response.value.data
              photos.push({
                id: photo.id,
                photo: {
                  src: photo.src,
                },
              })
              this.snackbarSuccess(this.$t('views.products.forms.messages.photos.upload_succeed'))
            } else {
              this.snackbarError(this.$t('views.products.forms.messages.photos.upload_failed', { error: 'internal' }))
            }
          })
          this.photos = photos
        })
        .catch((error) => {
          let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.snackbarError(this.$i18n.t('views.products.forms.messages.upload_failed', { error: error_message }))
        })
        .finally(() => {
          this.loading = false
          // this counter is used to force rerender of the component to reset state
          ++this.uploadKey
        })
    },
    deletePhoto(index) {
      const photos = [...this.photos]
      photos.splice(index, 1)
      this.photos = photos
    },
    zoom(photoId) {
      this.zoomPhotoId = photoId
      this.zoomShow = true
    },
  },
}
</script>

<style scoped>
.v-image.photo-state-deleted  {
  opacity: 0.5;
  border: 3px #CCC outset;
}
</style>
